<template>
    <div class="page-gray">
        <div class="head">
            <div class="item">
                <span>年度分润</span>
                <div class="value">
                    <span>8888</span>
                    <i>元</i>
                </div>
            </div>

            <div class="bottom">
                <div class="item">
                    <span>年度订单</span>
                    <div class="value">
                        <span>8888</span>
                        <i>个</i>
                    </div>
                </div>
                <div class="item">
                    <span>年度消费积分</span>
                    <div class="value">
                        <span>18888888</span>
                        <i>积分</i>
                    </div>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="title">
                <div class="left" v-if="!month">本月统计</div>
                <div class="left" v-else>本月统计</div>
                <div class="handle" @click="monthPopup = true">
                    <div class="placehoder" v-if="!month">选择月份</div>
                    <div class="value" v-else>{{month}}</div>
                    <img src="./../../assets/images/integral/arrow.png" alt="">
                </div>
            </div>

            <div class="content">
                <div class="item">
                    <div class="key">分润</div>
                    <div class="value">20000</div>
                </div>
                 <div class="item">
                    <div class="key">订单数</div>
                    <div class="value">20000</div>
                </div>
                 <div class="item">
                    <div class="key">消费积分</div>
                    <div class="value">20000</div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="title">
                <div class="left" >时间段筛选</div>
                <div class="handle" @click="startTimePopup = true">
                    <div class="value" v-if="startTime"> {{startTime}}</div>
                    <div class="placehoder" v-else>选择开始时间</div>
                    <img src="./../../assets/images/integral/arrow.png" alt="">
                    <i />
                    <div class="value" v-if="endTime"> {{endTime}}</div>
                    <div class="placehoder" v-else>选择结束时间</div>
                    <img src="./../../assets/images/integral/arrow.png" alt="">
                </div>
            </div>

            <div class="content" v-if="startTime">
                <div class="item">
                    <div class="key">分润</div>
                    <div class="value">20000</div>
                </div>
                 <div class="item">
                    <div class="key">订单数</div>
                    <div class="value">20000</div>
                </div>
                 <div class="item">
                    <div class="key">消费积分</div>
                    <div class="value">20000</div>
                </div>
            </div>

            <div class="content" v-else>
                <div class="empty" style="color: #999">暂无数据</div>
            </div>
        </div>

        <van-cell  title="我的积分订单" @click="$router.push('/integral/orderList')" is-link/>

         <van-popup v-model="monthPopup" round position="bottom">
           <van-picker
                title="选择月份"
                show-toolbar
                :columns="monthList"
                @confirm="onMonthConfirm"
            />
        </van-popup>

        <van-popup v-model="startTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择开始时间"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onStartConfirm"
            />
        </van-popup>

        <van-popup v-model="endTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择结束时间"
                :min-date="startTimeStack"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onEndConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "IntergralIndex",

    data() {
        return {
            startTimePopup: false,
            endTimePopup: false,
            monthPopup: false,
            maxDate: new Date(),
            startTimeStack: '',

            monthList: [],
            month: '',
            startTime: '',
            endTime: '',
            
        }
    },

    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },

        onMonthConfirm(val) {
            // val => {text: 1月, value: 1}
            this.month = val.text;
            this.monthPopup = false
        },

        onStartConfirm(val) {
            this.startTimeStack = val;
            this.startTimePopup = false;
            this.endTimePopup = true
        },
        onEndConfirm(val) {
            let date = new Date(this.startTimeStack)
            this.startTime = moment(date).format('YYYY-MM-DD')

            let endDate = new Date(val);
            this.endTime = moment(endDate).format('YYYY-MM-DD')
            this.endTimePopup = false


            
       }
    },

    mounted() {
        let maxMonth = new Date().getMonth() + 1;
        for (let i = 1; i <= maxMonth; i++) {
            this.monthList.push({
                text: `${i}月`,
                value: i
            })
        }
    }


}

</script>
<style lang="less" scoped>
.head {
    height: 140px;
    background-image: url('./../../assets/images/integral/bg.png');
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item {
        padding-bottom: 3px ;
        > span {
            font-size: 11px;
            font-weight: 500;
            color: #88543D;
            line-height: 25px;
            opacity: 0.5;
        }

        .value {
            span {
                font-size: 20px;
                font-weight: bold;
                color: #88543D;
                line-height: 1;
            }

            i {
                font-style: normal;
                font-size: 12px;
                color: #88543D;
                margin-left: 3px;
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .item {
            padding-left: 39px;

            &:first-child {
                padding-right: 34px;
                border-right:1px solid #B98b4e ;
            }
        }
    }
}

.van-cell {
    margin: 10px 18px;
    border-radius: 5px;
    width: auto;
}
.card {
    margin: 10px 18px;
    background: #FFFFFF;
    border-radius: 5px;

    padding: 10px 18px 0 18px;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            width:20px;
            height: 1px;
            background-color: #212121;
            display: inline-block;
            margin: 0 5px;
        }
        .left {
            font-size: 14px;
            font-weight: 500;
            color: #212121;
            line-height: 18px;
        }

        .handle {
            display: flex;
            align-items: center;
            div {
                font-size: 10px;
                &.placehoder {
                    color: #999;
                }

                &.value {
                    color: #212121;
                }
            }
            img {
                height: 5px;
                width: 8px;
                margin-left: 7px;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 30px 0 27px 0;
        text-align: center;

        .key {
            font-size: 12px;
            color: #666666;
        }
        .value {
            font-size: 13px;
            color: #666;
            margin-top: 3px;
            font-weight: 500;
        }
    }
}
</style>
